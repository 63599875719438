import Vue from 'vue'

export const eventBus = new Vue()

export const notif = (payload) => {
  let err = payload.err? payload.err: payload.error? payload.error: undefined
  let msg = payload.message? payload.message: payload.msg? payload.msg: payload
  if (!!err) {
    err = err.error? err.error: err.message? err.message: JSON.stringify(err)
  }

  const notice = {
    id: payload.id? payload.id: undefined,
    type: payload.type? payload.type: 'info',
    message: msg,
    payload: err,
    timeout: payload.timeout? payload.timeout: 0,
    loading: payload.loading? payload.loading: false,
  }

  if (notice.loading) {
    notice.timeout = 0
  }

  eventBus.$emit('show_message', notice)
}

export const notifInfo = (msg, timeout) => notif({type: "info", message: msg, timeout: timeout || 3000})
export const notifSuccess = (msg, timeout) => notif({type: "success", message: msg, timeout: timeout || 3000})
export const notifWarning = (msg, timeout) => notif({type: "warning", message: msg, timeout: timeout || 5000})
export const notifError = (msg, err, timeout = 0) => notif({type: "error", message: msg, err: err, timeout: timeout || 0})

const instance = {}
instance.install = (Vue) => {
  Vue.prototype.$notifHide = (id) => {
    eventBus.$emit('hide_message', id)
  }
  Vue.prototype.$notif = notif
  Vue.prototype.$notifInfo = notifInfo
  Vue.prototype.$notifSuccess = notifSuccess
  Vue.prototype.$notifWarning = notifWarning
  Vue.prototype.$notifError = notifError
}

Vue.use(instance)

export default instance
