// package: customer_ui
// file: customer-ui/project.proto

var customer_ui_project_pb = require("../customer-ui/project_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var Project = (function () {
  function Project() {}
  Project.serviceName = "customer_ui.Project";
  return Project;
}());

Project.CheckAvailabilityOfNewProjectID = {
  methodName: "CheckAvailabilityOfNewProjectID",
  service: Project,
  requestStream: false,
  responseStream: false,
  requestType: customer_ui_project_pb.CheckAvailabilityOfNewProjectIDRequest,
  responseType: customer_ui_project_pb.CheckAvailabilityOfNewProjectIDResponse
};

Project.Create = {
  methodName: "Create",
  service: Project,
  requestStream: false,
  responseStream: false,
  requestType: customer_ui_project_pb.CreateProjectRequest,
  responseType: customer_ui_project_pb.CreateProjectResponse
};

Project.MyProjects = {
  methodName: "MyProjects",
  service: Project,
  requestStream: false,
  responseStream: false,
  requestType: customer_ui_project_pb.MyProjectsRequest,
  responseType: customer_ui_project_pb.MyProjectsResponse
};

Project.ShortInfo = {
  methodName: "ShortInfo",
  service: Project,
  requestStream: false,
  responseStream: false,
  requestType: customer_ui_project_pb.ProjectShortInfoRequest,
  responseType: customer_ui_project_pb.ProjectShortInfoResponse
};

Project.Unpublish = {
  methodName: "Unpublish",
  service: Project,
  requestStream: false,
  responseStream: false,
  requestType: customer_ui_project_pb.UnpublishProjectRequest,
  responseType: customer_ui_project_pb.UnpublishProjectResponse
};

Project.SetupGitRepo = {
  methodName: "SetupGitRepo",
  service: Project,
  requestStream: false,
  responseStream: false,
  requestType: customer_ui_project_pb.SetupGitRepoRequest,
  responseType: customer_ui_project_pb.SetupGitRepoResponse
};

Project.GitRepoInfo = {
  methodName: "GitRepoInfo",
  service: Project,
  requestStream: false,
  responseStream: false,
  requestType: customer_ui_project_pb.GitRepoInfoRequest,
  responseType: customer_ui_project_pb.GitRepoInfoResponse
};

Project.PullFromGitRepo = {
  methodName: "PullFromGitRepo",
  service: Project,
  requestStream: false,
  responseStream: false,
  requestType: customer_ui_project_pb.PullFromGitRepoRequest,
  responseType: customer_ui_project_pb.PullFromGitRepoResponse
};

Project.AvailableVersions = {
  methodName: "AvailableVersions",
  service: Project,
  requestStream: false,
  responseStream: false,
  requestType: customer_ui_project_pb.AvailableProjectVersionsReqeust,
  responseType: customer_ui_project_pb.AvailableProjectVersionsResponse
};

Project.Release = {
  methodName: "Release",
  service: Project,
  requestStream: false,
  responseStream: false,
  requestType: customer_ui_project_pb.ReleaseProjectVersionRequest,
  responseType: customer_ui_project_pb.ReleaseProjectVersionResponse
};

exports.Project = Project;

function ProjectClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

ProjectClient.prototype.checkAvailabilityOfNewProjectID = function checkAvailabilityOfNewProjectID(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Project.CheckAvailabilityOfNewProjectID, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ProjectClient.prototype.create = function create(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Project.Create, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ProjectClient.prototype.myProjects = function myProjects(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Project.MyProjects, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ProjectClient.prototype.shortInfo = function shortInfo(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Project.ShortInfo, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ProjectClient.prototype.unpublish = function unpublish(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Project.Unpublish, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ProjectClient.prototype.setupGitRepo = function setupGitRepo(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Project.SetupGitRepo, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ProjectClient.prototype.gitRepoInfo = function gitRepoInfo(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Project.GitRepoInfo, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ProjectClient.prototype.pullFromGitRepo = function pullFromGitRepo(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Project.PullFromGitRepo, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ProjectClient.prototype.availableVersions = function availableVersions(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Project.AvailableVersions, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ProjectClient.prototype.release = function release(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(Project.Release, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.ProjectClient = ProjectClient;

