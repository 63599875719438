<template>
  <v-app>
    <v-app-bar app>
        <v-app-bar-nav-icon @click.stop="drawer = !drawer" v-if="false"></v-app-bar-nav-icon>

        <v-toolbar-title>
          <router-link to="/" style="color: inherit; text-decoration: inherit;">Fader4 Cloud Platform</router-link>
        </v-toolbar-title>

        <span class="mx-5" />

        <v-btn v-if="isAuth" depressed tile class="pa-0 mr-5">
          <v-btn v-if="!!defaultProjectId" tile text to="/">
            {{ defaultProjectName }}
          </v-btn>
          <v-btn v-else tile text @click="openFastProjectSwitching">
            {{ $t('header.select_project') }}
          </v-btn>

          <v-btn tile text @click="openFastProjectSwitching" style="min-width: 35px;" class="pa-0">
            <v-icon>mdi-menu-down</v-icon>
          </v-btn>
        </v-btn>

        <v-spacer></v-spacer>

        <v-btn v-if="!isAuth" text :to="{name: 'SignIn'}">
          {{ $t('btn.signin') }}
        </v-btn>
        <v-menu
          v-if="isAuth"
          left
          bottom
          offset-y
        >
          <template v-slot:activator="{ on }">
            <div v-on="on" style="cursor: pointer;">
              <v-container>
                  <v-layout row>
                    <strong>{{ profile.displayName}}</strong>
                  </v-layout>
                  <v-layout row>
                    <v-list-item-subtitle>{{ profile.email}}</v-list-item-subtitle>
                  </v-layout>
              </v-container>
            </div>
          </template>

          <v-list>
            <v-list-item link :to="{name: 'ProjectCreate'}">
              <v-list-item-action><v-icon>mdi-plus</v-icon></v-list-item-action>
              <v-list-item-content>
                <v-list-item-title >
                  {{ $t('btn.project_create') }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item link :to="{name: 'ProjectManager'}">
              <v-list-item-action><v-icon>mdi-apps</v-icon></v-list-item-action>
              <v-list-item-content>
                <v-list-item-title >
                  {{ $t('btn.project_manager') }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-divider/>

            <v-list-item link :to="{name: 'Profile'}">
              <v-list-item-action><v-icon>mdi-account-circle</v-icon></v-list-item-action>
              <v-list-item-content>
                <v-list-item-title >
                  {{ $t('btn.profile') }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-divider/>

            <v-list-item link :to="{name: 'SignOut'}">
              <v-list-item-action><v-icon>mdi-logout</v-icon></v-list-item-action>
              <v-list-item-content>
                <v-list-item-title >
                  {{ $t('btn.signout') }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item-title class="mx-2 mt-2 text-caption text--disabled">
              {{ backendVersion }} <a class="text-caption text--disabled" href="" target="_blank">{{ $t('goto_changelog') }}</a>
            </v-list-item-title>
          </v-list>
        </v-menu>


    </v-app-bar>

    <v-navigation-drawer
      v-if="false"
      v-model="drawer"
      absolute
      bottom
      temporary>

      <v-list-item v-if="!!defaultProjectId">
        <v-list-item-content>
          <v-list-item-title class="title">
            {{ defaultProjectName }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-card-text v-if="!defaultProjectId">{{ $t('select_or_create_project') }}</v-card-text>
      <v-divider v-if="!!defaultProjectId"/>
      <v-list
        nav
        dense
        v-if="!!defaultProjectId"
      >
        <v-list-item-group        >
          <v-list-item link :to="{name: 'Home'}">
            <v-list-item-title>Home</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <Notifer />
    <FastProjectSwitching
      :show.sync="showFastProjectSwitching"
      @done="handlerFastProjectSwitching"
    />

    <v-main>
      <v-container grid-list-md>
        <v-layout row wrap>
          <router-view/>
        </v-layout>
      </v-container>
      <!-- Provides the application the proper gutter -->
      <!-- <v-container>
        <router-view/>
      </v-container> -->
    </v-main>
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex'
import Notifer from "@/components/Notifer"
import FastProjectSwitching from "@/components/FastProjectSwitching"
import projects from "./services/projects"

export default {
  name: 'App',
  created () {
  },
  computed: {
    ...mapGetters({
      isAuth: 'session/isAuth',
      profile: 'session/profile',
      token: 'session/token',

      defaultProjectId: 'app/defaultProjectId',
      defaultProjectName: 'app/defaultProjectName',
      backendVersion: 'app/backendVersion',
    }),
  },
  mounted() {
    // setTimeout(() => {
    //   document.getElementsByClassName('firebase-emulator-warning')[0].remove()
    // }, 1000)
  },

  components: {
    Notifer,
    FastProjectSwitching
  },

  methods: {
    openFastProjectSwitching() {
      // TODO: проверить сколько пиоектов
      this.showFastProjectSwitching = true
    },
    handlerFastProjectSwitching ({project, show}) {
      this.showFastProjectSwitching = show

      if (project) {
        projects.switchToProject(project.projectId)

        const current = this.$route.name == 'ProjectOverview' && this.$route.params.projectId == project.projectId

        if (!current) {
          this.$router.push({name: 'ProjectOverview', params: {projectId: project.projectId}})
        }
      }
    }
  },

  data: () => ({
    drawer: false,
    showFastProjectSwitching: false,
  }),
};
</script>
