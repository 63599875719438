import Vue from 'vue'
import Vuex from 'vuex'

import createPersistedState from 'vuex-persistedstate'

import session from './modules/session'
import app from './modules/app'
import projects from './modules/projects'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    session,
    app,
    projects,
  },
  plugins: [
    createPersistedState({
      key: 'fader4console',
      paths: [
        'session',
        'app'
      ],
    }),
  ],

  state: {
    packageVersion: process.env.PACKAGE_VERSION || '0'
  },
  getters: {
    packageVersion: (state) => {
      return state.packageVersion
    }
  }
})
