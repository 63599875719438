const firebaseConfig = {
  apiKey: "AIzaSyDFn0q3A_C4J3KsEF3Gimk7HQGXLz6cr40",
  authDomain: "fader4.firebaseapp.com",
  databaseURL: "https://fader4.firebaseio.com",
  projectId: "fader4",
  storageBucket: "fader4.appspot.com",
  messagingSenderId: "351137205987",
  appId: "1:351137205987:web:bbf652b6a451a6d8747fd6",
  measurementId: "G-HNSRZDM6RJ"
};
export default firebaseConfig;
