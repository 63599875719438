const state = {
  defaultProject: null,
  backendVersion: null,
}

const getters = {
  defaultProjectId: (state) => state.defaultProject? state.defaultProject.projectId : null,
  defaultProjectName: (state) => state.defaultProject? state.defaultProject.name : null,
  backendVersion: (state) => state.backendVersion,
}

const mutations = {
  setDefaultProject(state, project) {
    console.log('setDefaultProject', project)
    state.defaultProject = {projectId: project.info.projectId, name: project.info.humanName}
  },
  clearDefaultProject(state) {
    this.defaultProject = null
  }
}
const actions = {
  setBackendVersion({state}, version) {
    state.backendVersion = version
  },
  clearAppState({state}) {
    state.selectedProject = null
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
