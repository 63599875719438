<template>
  <div>
    Welcome
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'Welcome',
  computed: {
    ...mapGetters({
      isAuth: 'session/isAuth',
      profile: 'session/profile',
      token: 'session/token',
    }),
  },
  components: {
  },
  watch: {
    isAuth(newVal) {
      if (newVal) {
        this.$router.push({name: "Home"})
      }
    }
  }
}
</script>
