

import * as jspb from "google-protobuf"
import lodash from "lodash"

const protoObjMapper = (v) => {
  if (_.has(v, '$jspbMessageInstance')) {
    // case kindCase.NULL_VALUE:
    //   return null;
    // case kindCase.NUMBER_VALUE:
    //   return v.$jspbMessageInstance.getNumberValue();
    // case proto.google.protobuf.StringValue:
    //   return v.$jspbMessageInstance.getStringValue();
    // case kindCase.BOOL_VALUE:
    //   return v.$jspbMessageInstance.getBoolValue();
    // case kindCase.STRUCT_VALUE:
    //   return v.$jspbMessageInstance.getStructValue().toJavaScript();
    // case kindCase.LIST_VALUE:
    //   return v.$jspbMessageInstance.getListValue().toJavaScript();

    switch (v.$jspbMessageInstance.constructor) {
      case proto.google.protobuf.BoolValue:
        alert("boolval")
      case proto.google.protobuf.StringValue:
        return v.$jspbMessageInstance.getValue()
      case proto.google.protobuf.Timestamp:
        return v.$jspbMessageInstance.toDate()
      case proto.google.protobuf.Struct:
        var res
        try {
          res = v.$jspbMessageInstance.toJavaScript()
        } catch(err) {
          console.error("Failed convert struct to js object", v.$jspbMessageInstance)
          res = null
        }
        return res
      case proto.google.protobuf.Value:
        var res
        try {
          res = v.$jspbMessageInstance.toJavaScript()
        } catch(err) {
          console.error("Failed convert abstract value to js object", v.$jspbMessageInstance)
          res = null
        }
        return res
    }

    delete v.$jspbMessageInstance
  }
  return v
}

// From https://blog.logrocket.com/programmatic-file-downloads-in-the-browser-9a5186298d5c/
function downloadBlob(blob, filename) {
  // Create an object URL for the blob object
  const url = URL.createObjectURL(blob);

  // Create a new anchor element
  const a = document.createElement('a');

  // Set the href and download attributes for the anchor element
  // You can optionally set other attributes like `title`, etc
  // Especially, if the anchor element will be attached to the DOM
  a.href = url;
  a.download = filename || 'download';

  // Click handler that releases the object URL after the element has been clicked
  // This is required for one-off downloads of the blob content
  const clickHandler = () => {
    setTimeout(() => {
      URL.revokeObjectURL(url);
      a.removeEventListener('click', clickHandler);
    }, 150);
  };

  // Add the click event listener on the anchor element
  // Comment out this line if you don't want a one-off download of the blob content
  a.addEventListener('click', clickHandler, false);

  // Programmatically trigger a click on the anchor element
  // Useful if you want the download to happen automatically
  // Without attaching the anchor element to the DOM
  // Comment out this line if you don't want an automatic download of the blob content
  a.click();

  // Return the anchor element
  // Useful if you want a reference to the element
  // in order to attach it to the DOM or use it in some other way
  return a;
}

lodash.mixin({
  deep: function (obj, mapper) {
      return mapper(_.mapValues(obj, (v) => {
          // Сохраням прототип объектов (как правило в переменной $jspbMessageInstance)
          if (v instanceof jspb.Message) {
            return v
          }

          // Массив итерируем
          if (v instanceof Array) {
            return _(v).map((v) => _.isObjectLike(v) ? _.deep(v, mapper) : v)
          }

          // Объект раскрываем либо оставляем как есть
          return _.isObjectLike(v) ? _.deep(v, mapper) : v;
      }));
  },
});

export default {
  downloadURL (url, filename) {
    // Create a new anchor element
    const a = document.createElement('a');

    // Set the href and download attributes for the anchor element
    // You can optionally set other attributes like `title`, etc
    // Especially, if the anchor element will be attached to the DOM
    a.href = url;
    a.download = filename || 'download';

    // Click handler that releases the object URL after the element has been clicked
    // This is required for one-off downloads of the blob content
    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(url);
        a.removeEventListener('click', clickHandler);
      }, 150);
    };

    // Add the click event listener on the anchor element
    // Comment out this line if you don't want a one-off download of the blob content
    a.addEventListener('click', clickHandler, false);

    // Programmatically trigger a click on the anchor element
    // Useful if you want the download to happen automatically
    // Without attaching the anchor element to the DOM
    // Comment out this line if you don't want an automatic download of the blob content
    a.click();

    // Return the anchor element
    // Useful if you want a reference to the element
    // in order to attach it to the DOM or use it in some other way
    return a;
  },
  downloadBlob (blob, filename) {
    return downloadBlob(blob, filename)
  },
  // Вспомогательный методы трансформирующий jspb.Message в обычный JavaScript объект.
  pbToObject (obj) {
    if (!(obj instanceof jspb.Message)) {
      return obj
    }

    const res = _.deep(obj.toObject(true), protoObjMapper)

    return JSON.parse(JSON.stringify(res))
  },
  fetchText (url) {
    return fetch(url, { method: 'GET' }).then((response) => {
      if (response.status === 200) {
        return response.text().then((text) => {
          return text
        })
      }
    })
  },
}
