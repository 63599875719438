<template>
  <v-container>
    <v-row no-gutters v-if="project == null">
      <v-skeleton-loader type="text"/>
      <v-skeleton-loader type="button"/>
      <v-divider vertical class="mx-2"/>
      <v-skeleton-loader type="button"/>
    </v-row>
    <v-row no-gutters v-else>
      <v-card-title class="mr-5 pa-0" style="position: relative;">
        {{ project.info.humanName }}
        <span class="text--disabled caption" style="position: absolute; bottom: -0.8em;">
          {{ project.info.projectId }}
        </span>
      </v-card-title>
      <v-btn tile text :to="{name: 'ProjectOverview'}">Overview</v-btn>
      <v-divider vertical class="mx-2"/>
      <v-btn text tile :to="{name: 'ProjectRelease'}">Release</v-btn>
      <v-divider vertical class="mx-2"/>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            text
            tile
            v-bind="attrs"
            v-on="on"
            style="min-width: 35px;" class="pa-0"
          >
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item link :to="{name: 'ProjectGitRepository'}">
            <v-list-item-title>Git Repository</v-list-item-title>
          </v-list-item>
          <v-list-item link :to="{name: 'ProjectSettings'}">
            <v-list-item-title>Settings</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-row>
    <v-row no-gutters>
      <router-view />
    </v-row>
  </v-container>
</template>


<script>
import projects from '../../services/projects'

export default {
  name: 'CustomerLayout',
  created () {
    if (this.$route.params.projectId) {
      this.setupProject(this.$route.params.projectId)
    }
  },
  methods: {
    setupProject(projectId) {
      this.project = null
      projects.switchToProject(projectId)
      projects.getProjectById(projectId).then(project => {this.project = project})
    },
    openBlankURL(url) {
      window.open(url, '_blank')
    }
  },
  computed: {
    loadingProject() {
      return this.project == null
    }
  },
  data: () => ({
    project: null
  }),
  watch: {
    '$route.params.projectId'(projectId) {
      this.setupProject(projectId)
    }
  }
}
</script>


// <v-container v-if="false">
//     <v-row no-gutters class="mb-5">
//       <v-skeleton-loader
//         v-if="!selectedProject"
//         type="text"
//         width="100%"
//         height="50px"
//       ></v-skeleton-loader>
//       <v-text-field
//         v-else
//         dense
//         readonly
//         prepend-icon="mdi-open-in-new"
//         @click:prepend="openBlankURL(selectedProject.projectUrl)"
//         :value="selectedProject.projectUrl"
//         />
//     </v-row>
//     <v-row no-gutters class="mb-5">
//       <!-- <v-btn tile text :href="selectedProject.projectUrl" target="_blank"><v-icon small left >mdi-open-in-new</v-icon>{{ selectedProject.projectUrl }}</v-btn> -->
//       <div v-if="!selectedProject" class="project-menu-buttons-loading-skeleton row">
//         <v-skeleton-loader
//           type="actions"
//         ></v-skeleton-loader>
//         <v-skeleton-loader
//           type="actions"
//         ></v-skeleton-loader>
//         <v-skeleton-loader
//           type="actions"
//         ></v-skeleton-loader>
//       </div>
//       <div class="project-menu-buttons" v-else>
//         <v-btn tile text :to="{name: 'ProjectOverview'}"><v-icon left>mdi-home-outline</v-icon>Overview</v-btn>
//         <v-btn tile text :to="{name: 'ProjectRelease'}"><v-icon left>mdi-cloud-sync-outline</v-icon> Release</v-btn>
//         <v-menu offset-y>
//           <template v-slot:activator="{ on, attrs }">
//             <v-btn
//               text
//               tile
//               v-bind="attrs"
//               v-on="on"
//             >
//               <v-icon>mdi-dots-vertical</v-icon>
//             </v-btn>
//           </template>
//           <v-list>
//             <v-list-item link :to="{name: 'ProjectGitRepository'}">
//               <v-list-item-title>Git Repository</v-list-item-title>
//             </v-list-item>
//             <v-list-item link :to="{name: 'ProjectSettings'}">
//               <v-list-item-title>Settings</v-list-item-title>
//             </v-list-item>
//           </v-list>
//         </v-menu>
//       </div>
//     </v-row>
//     <v-row no-gutters>
//       <router-view />
//     </v-row>
//   </v-container>
