import Vue from 'vue'
import { grpc } from '@improbable-eng/grpc-web'
import store from '../store'
import router from '../router'
import {notifError} from '../plugins/notif'
// import utils from './utils'

// идентификаторы ключа SessionID в metadata grpc реквеста
const AccessTokenMDKey = 'access-token'

// идентификаторы ключа backend-version в трейлере grpc респонса
const BackendVersion_TRAILERKEY = 'backend-version'

export default {
  accessToken () {
    return store.getters['session/token']
  },
  signout(errMsg) {
    notifError(errMsg)
    router.push({name: 'SignOut'})
  },
  stream ({backendURL}, { methodDescriptor, request, metadata, muteCodes = [] }) {
    if (backendURL === '') {
      throw new Error('api: backendURL is empty')
    }
    const accessToken = this.accessToken()

    const authMetaData = accessToken ? { [AccessTokenMDKey]: accessToken } : null
    metadata = Object.assign(metadata || {}, authMetaData)


    const requestPromise = new Promise((resolve, reject) => {
      const eventBus = new Vue()

      const res = grpc.invoke(methodDescriptor, {
        request: request,
        host: backendURL,
        metadata: new grpc.Metadata(metadata),
        onHeaders: (message) => {
          eventBus.$emit('on_headers', message)
        },
        onMessage: (message) => {
          // eventBus.$emit('on_message', utils.pbToObject(message))
        },
        onEnd: (code, msg, trailers) => {
          eventBus.$emit('on_end', {code, msg, trailers})
          if (code == grpc.Code.OK) {
            console.log("all ok")
          } else {
            console.log("hit an error", code, msg, trailers);
          }
        }
      });

      // TODO: handle error?
      // TODO: unsubs if to shutdown the application?
      resolve(eventBus)
    })

    return requestPromise
  },
  unary ({backendURL}, { methodDescriptor, request, metadata, muteCodes = [] }) {
    if (backendURL === '') {
      throw new Error('api: backendURL is empty')
    }

    const accessToken = this.accessToken()

    const authMetaData = accessToken ? { [AccessTokenMDKey]: accessToken } : null
    metadata = Object.assign(metadata || {}, authMetaData)

    const requestPromise = new Promise((resolve, reject) => {
      grpc.unary(methodDescriptor, {
        request: request,
        host: backendURL,
        metadata: new grpc.Metadata(metadata),
        onEnd: res => {
          const { status, statusMessage, headers, message, trailers } = res
          res.fullMethod = methodDescriptor.service.serviceName + '/' + methodDescriptor.methodName
          res.backendVersion = trailers.get(BackendVersion_TRAILERKEY)[0]

          if (message && status === grpc.Code.OK) {
            resolve(res)
            return
          }

          const errorInfo = {
            origRequest: request,
            origResponse: res,
            fullMethod: res.fullMethod,

            accessToken: accessToken,

            backendVersion: res.backendVersion,

            trailers: trailers,
            headers: headers,
            statusMessage: statusMessage,
            status: status,
            request: request,

            error: res.fullMethod + ': '+statusMessage
          }
          reject(errorInfo)

          if (status === grpc.Code.PermissionDenied && statusMessage === 'Invalid session.') {
            // TODO: logout session?
            this.signout('Session expired or invalid session. Please resigin.')
          } else {
            if (muteCodes.indexOf(status) == -1) {
              notifError('Request failed', errorInfo)
            }
          }
        }
      })
    })

    return requestPromise
  }
}
