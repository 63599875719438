<template>
  <v-snackbar
    v-if="current"
    v-model="current.show"
    :timeout="current.timeout"
    :color="current.type"
    top
    vertical
    multi-line
    @input="onChange(current)"
  >
    <template v-slot:action="{ attrs }">
      <v-badge :value="queueLenght > 0" :content="queueLenght+1" color="info">
        <v-btn
          v-if="!current.loading"
          dark text left v-bind="attrs"
          @click="remove(current.id)"><v-icon>mdi-close</v-icon></v-btn>
        <v-btn
          v-if="current.loading && queueLenght > 0"
          dark text left v-bind="attrs"
          @click="skip(current.id)"><v-icon>mdi-skip-forward</v-icon></v-btn>
      </v-badge>
    </template>

    {{ $t(current.message) }}
    <p v-if="!!current.payload"><i>{{current.payload }}</i></p>
    <div class="mt-5" />
    <v-progress-linear v-if="current.loading" indeterminate color="white" />
  </v-snackbar>
</template>

<script>
import {eventBus} from "../plugins/notif"
export default {
  name: "Notifer",
  created () {
    eventBus.$on("show_message", (dat) => {
      this._seq++
      const forceNext = this.queueLenght == 0 && !this.current
      this.queue.push({...dat, order: this._seq, show: true})
      if (forceNext) {
        this.next()
      }
    })
    eventBus.$on("hide_message", (id) => {
      this.queue = this.queue.filter(item => item.id !== id);

      if (this.current && this.current.id == id) {
        this.next()
      }
    })
  },
  computed: {
    queueLenght () {
      return this.queue.length
    }
  },
  data () {
    return {
      _seq: 0,
      queue: [],
      current: undefined
    }
  },
  methods: {
    skip(id) {
      this.$notif(this.current)
      this.next()
    },
    remove(id) {
      this.next()
    },
    onChange(newVal) {
      if (!newVal.show) {
        this.next()
      }
    },
    next() {
      this.current = undefined
      const next = this.queue.shift()
      if (next) {
        this.$nextTick(() => (this.current = {...next}));

      }
    }
  },
}
</script>
