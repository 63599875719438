import grpc from './grpc'

import session from './session'
import project from './project'
import utils from './utils'

import store from '../store'

export default {
  session,
  project,

  backendURL: '',
  currentBackendVersion: null,
  setBackendURL (backendURL) {
    this.backendURL = backendURL
  },
  promiseError (error) {
    return new Promise((resolve, reject) => {
      reject(new Error(error));
    })
  },
  stream ({method, request, opts}) {
    if (!this.backendURL) {
      throw new Error('api: backendURL is empty')
    }
    return grpc.stream({backendURL: this.backendURL}, {methodDescriptor: method, request: request, opts: opts})
  },
  unary ({method, request, opts = {muteCodes: []}}) {
    return new Promise((resolve, reject) => {
      try {
        this._unary({
          methodDescriptor: method,
          request,
          muteCodes: opts.muteCodes || [],
        })
        .then((response) => {
          store.dispatch('app/setBackendVersion', response.backendVersion)
          return response
        })
        .then((response) => utils.pbToObject(response.message))
        .then((response) => resolve(response))
        .catch((error) => reject(error))
      } catch (error) {
        reject(error)
      }
    })
  },
  _unary (props) {
    if (!this.backendURL) {
      throw new Error('api: backendURL is empty')
    }
    return grpc.unary({backendURL: this.backendURL}, props)
  },
}
