import Firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/storage';
import firebaseConfig from '../config/firebase';

!Firebase.apps.length ? Firebase.initializeApp(firebaseConfig) : '';

// if(window.location.hostname === 'localhost') {
//   Firebase.firestore().useEmulator('localhost', 8080);
//   Firebase.functions().useEmulator('localhost', 5001);
//   Firebase.auth().useEmulator('http://localhost:9099');
//   Firebase.storage().useEmulator('localhost', 5001);
// }

// export const GoogleAuthProvider = new Firebase.auth.GoogleAuthProvider();
export const FirebaseAuth = Firebase.auth();
export const Firestore = Firebase.firestore();
export const FirebaseStorage = Firebase.storage();
export default Firebase;
