import store from '../store'
import api from '../api'
import i18n from '../i18n'
import {notifInfo, notifError} from '../plugins/notif'
import router from '../router';
import { times } from 'lodash';

class MyService {
  myVar = 1;

  constructor() {
  }

  getDefaultProject() {
    return this.getProjectById(store.getters['app/defaultProjectId'])
  }

  setRepoCreds({projectId, repoUrl, repoUsername, repoPwd}) {
    return api.project.setRepoCreds({projectId, repoUrl, repoUsername, repoPwd}).then(res => {
      this.loadProjectById(projectId)
    })
  }

  switchToProject(projectId) {
    return this.getProjectById(projectId).then(res=> {
      store.commit('app/setDefaultProject', res)
    }).catch(err => {
      router.push({name: 'ProjectManager'})
      store.commit('app/clearDefaultProject')
      notifError(i18n.t('notif.failed_load_project', [projectId]))
    })
  }

  async getProjectById(projectId) {
    const existsProject = store.getters["projects/projectById"](projectId)
    const promiseLoadProject = this.loadProjectById(projectId)
    if (!!existsProject)  {
      return existsProject
    }
    return await promiseLoadProject
  }

  loadProjectById(projectId) {
    return api.project.shortInfo({projectId: projectId}).then(res => {
      store.commit("projects/addOrUpdateProject", {projectId: res.info.projectId, ...res})
      return res
    })
  }
}

export default new MyService();
