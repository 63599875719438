import {notifInfo} from '../../plugins/notif'
import i18n from '../../i18n'
import api from '../../api'
const state = {
  uid: null,
  userProfile: null,
  userToken: null,
  waitingInitState: false,
}

const getters = {
  uid: (state) => state.uid,
  displayName: (state) => state.userProfile? state.userProfile.displayName: '',
  profile: (state) => state.userProfile,
  isAuth: (state) => !!state.uid && !!state.userToken,
  token: (state) => state.userToken,
}

const mutations = {
  clearAll(state) {
    state.uid = null
    state.userProfile = null
    state.userToken = null
  },
  setProfile(state, profile) {
    state.uid = profile.uid
    state.userProfile = profile
  },
  startWaitingUserState(state) {
    state.waitingInitState = true
  },
  endWaitingUserState(state) {
    state.waitingInitState = false
  },
  setToken(state, token) {
    state.userToken = token
  },
}
const actions = {
  setUserProfile({state, commit, dispatch}, profile) {
    commit('endWaitingUserState')
    commit('setProfile', profile)
  },
  setToken({state, commit, dispatch}, token) {
    commit('setToken', token)
  },
  awaitingUserSessionIfExsits({state, commit, dispatch}, token) {
    commit('startWaitingUserState')
  },
  noSession({state, commit, dispatch}) {
    commit('clearAll')
    commit('endWaitingUserState')
  },
  logout({state, commit, dispatch}) {
    commit('clearAll')
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
