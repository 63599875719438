import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import firebase from './plugins/firebase';
import {FirebaseAuth} from './plugins/firebase';
import notifer from '@/plugins/notif'

import api from './api/index'

import i18n from './i18n'

Vue.config.productionTip = false
Vue.prototype.$firebase = firebase;

api.setBackendURL("https://console-ui-api-dot-fader4.ew.r.appspot.com")
if(window.location.hostname === 'localhost') {
  api.setBackendURL("http://localhost:4920")
}

new Vue({
  router,
  store,
  vuetify,
  i18n,
  notifer,
  created () {
    let vm = this
    if(window.location.hostname === 'localhost') {
      this.$nextTick().then((res) => {
        this.$notifInfo("localhost")
      })
    }

    vm.$store.dispatch("session/awaitingUserSessionIfExsits")

    FirebaseAuth.onAuthStateChanged((user) => {
      if (!user) {
        vm.$store.dispatch("session/noSession")
        return
      }

      const userData = user.toJSON();
      vm.$store.dispatch("session/setUserProfile", {
        uid: userData.uid,
        email: userData.email,
        displayName: userData.displayName,
        emailVerified: userData.emailVerified,
        isAnonymous: userData.isAnonymous,
        lastLoginAt: userData.lastLoginAt,
        createdAt: userData.createdAt,
      })

      if (FirebaseAuth.currentUser) {
        FirebaseAuth.currentUser.getIdTokenResult().then((token) => {
          vm.$store.dispatch("session/setToken", token.token)
        })
      }
    })
  },
  beforeCreate() {
  },
  render: h => h(App)
}).$mount('#app')
