import api from "."
import { grpc } from '@improbable-eng/grpc-web'

import {
  SetupGitRepoRequest,
  PullFromGitRepoRequest,
  GitRepoInfoRequest,
  GitRepoAndCreds,
  CheckAvailabilityOfNewProjectIDRequest,
  CreateProjectRequest,
  ProjectShortInfoRequest,
  MyProjectsRequest
} from "./customer-ui/project_pb"

import {
  Project
} from "./customer-ui/project_pb_service"

import {
  StringValue,
} from 'google-protobuf/google/protobuf/wrappers_pb'

export default {
  myProjects() {
    const request = new MyProjectsRequest()

    const method = Project.MyProjects
    return api.unary({method, request})
  },
  create ({projectId, hummanName, repo}) {
    const request = new CreateProjectRequest()
    request.setHumanName(hummanName)
    request.setProjectId(projectId)

    if (!!repo && !!repo.url) {
      const repo_ = new GitRepoAndCreds()
      repo_.setRepoUrl(repo.url)
      if (!!repo.username) {
        repo_.setRepoUsername(new StringValue([repo.username]))
      }
      if (!!repo.password) {
        repo_.setRepoPassword(new StringValue([repo.password]))
      }
      request.setRepo(repo_)
    }

    const method = Project.Create
    return api.unary({method, request})
  },
  checkAvailableProjectID({projectId}) {
    const request = new CheckAvailabilityOfNewProjectIDRequest()
    request.setProjectId(projectId)

    const method = Project.CheckAvailabilityOfNewProjectID
    return api.unary({
      method,
      request,
      opts: {
        muteCodes: [grpc.Code.AlreadyExists, grpc.Code.InvalidArgument]
      }
    })
  },
  shortInfo ({projectId}) {
    const request = new ProjectShortInfoRequest()
    request.setProjectId(projectId)
    const method = Project.ShortInfo
    return api.unary({
      method,
      request,
      opts: {
        muteCodes: [grpc.Code.NotFound, grpc.Code.InvalidArgument]
      }
    })
  },
  repoInfo ({projectId, repoUrl}) {
    const request = new GitRepoInfoRequest()
    request.setProjectId(projectId)
    request.setRepoUrl(repoUrl)
    const method = Project.GitRepoInfo
    return api.unary({
      method,
      request,
    })
  },
  repoPull ({projectId, repoUrl, hash}) {
    const request = new PullFromGitRepoRequest()
    request.setProjectId(projectId)
    request.setRepoUrl(repoUrl)
    request.setHash(new StringValue([hash]))
    const method = Project.PullFromGitRepo
    return api.unary({
      method,
      request,
    })
  },
  setRepoCreds ({projectId, repoUrl, repoUsername, repoPwd}) {
    const request = new SetupGitRepoRequest()
    request.setProjectId(projectId)
    const repo_ = new GitRepoAndCreds()
    repo_.setRepoUrl(repoUrl)
    if (!!repoUsername) {
      repo_.setRepoUsername(new StringValue([repoUsername]))
    }
    if (!!repoPwd) {
      repo_.setRepoPassword(new StringValue([repoPwd]))
    }
    request.setRepo(repo_)

    const method = Project.SetupGitRepo
    return api.unary({
      method,
      request,
    })
  }
}
