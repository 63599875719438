import Vue from 'vue'
import VueRouter from 'vue-router'

import Welcome from '../views/Welcome.vue'
import ProjectLayout from '../views/project/Layout.vue'

import { notifInfo }  from '../plugins/notif'
import {FirebaseAuth} from '../plugins/firebase';

import store from '../store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Index',
    beforeEnter (to, from, next) {
      // Если пользователь авторизирован то отправляем его в выбранный им ранее проект
      // В противном случае на форму авторизации
      if (store.getters['session/isAuth']) {
        const projectId = store.getters['app/defaultProjectId']
        if (projectId) {
          next({name: 'ProjectOverview', params: {projectId: projectId}})
        } else {
          next({name: 'ProjectManager'})
        }
      } else {
        next({name: 'SignIn'})
      }
    }
  },
  {
    path: '/project_manager',
    name: 'ProjectManager',
    component: () => import(/* webpackChunkName: "projectManager" */ '../views/ProjectManager.vue')
  },
  {
    path: '/project_create',
    name: 'ProjectCreate',
    component: () => import(/* webpackChunkName: "projectCreate" */ '../views/ProjectCreate.vue')
  },
  {
    path: '/project/:projectId',
    name: 'ProjectLayout',
    component: ProjectLayout,
    meta: {
      authRequired: true,
      // noDelegatedHandler: (to, from) => {
      //   if (to.params.projectId) {
      //     store.dispatch('session/verifySelectedProjectId', to.params.projectId)
      //   }
      // },
    },
    beforeEnter (to, from, next) {
      // Если мы явно переходим в `/project/:projectId` то отправляем пользоваетля на ProjectOverview
      // Если не задан projectId то отправляем на список проектов
      // В противном случае не мешаем ему идит туда куда он шел
      if (to.name == 'ProjectLayout' && to.params.projectId) {
        next({name: 'ProjectOverview', params: {projectId: to.params.projectId}})
      } else if (to.name == 'ProjectLayout' && !to.params.projectId) {
        next({name: 'ProjectManager'})
      } else {
        next()
      }
    },
    children: [
      {
        path: 'overview',
        name: 'ProjectOverview',
        component: () => import(/* webpackChunkName: "projectOverview" */ '../views/project/Overview.vue')
      },
      {
        path: 'release',
        name: 'ProjectRelease',
        component: () => import(/* webpackChunkName: "projectRelease" */ '../views/project/Release.vue')
      },
      {
        path: 'settings',
        name: 'ProjectSettings',
        component: () => import(/* webpackChunkName: "projectRelease" */ '../views/project/Settings.vue')
      },
      {
        path: 'git_repository',
        name: 'ProjectGitRepository',
        component: () => import(/* webpackChunkName: "projectGitRepository" */ '../views/project/GitRepository.vue')
      },
      {
        path: 'settings',
        name: 'ProjectSettings',
        component: () => import(/* webpackChunkName: "projectSettings" */ '../views/project/Settings.vue')
      },
    ]
  },

  {
    path: '/profile',
    name: 'Profile',
    component: () => import(/* webpackChunkName: "profile" */ '../views/Profile.vue'),
    meta: {
      authRequired: true,
    }
  },
  {
    path: '/logout',
    name: 'SignOut',
    meta: {
      authRequired: true,
    },
    beforeEnter (to, from, next) {
      FirebaseAuth.signOut().then(() => {
        store.dispatch('session/logout')
        next({name: "SignIn"})
      })
    }
  },
  {
    path: '/signin',
    name: 'SignIn',
    component: () => import(/* webpackChunkName: "signIn" */ '../views/SignIn.vue')
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  // HACK: для отслеживания изменения параметра
  // https://github.com/vuejs/vue-router/issues/1012
  // to.matched.filter(record => record.meta.noDelegatedHandler).forEach(record => record.meta.noDelegatedHandler(to, from))

  if (to.matched.some(record => record.meta.authRequired)) {
    if (store.getters['session/isAuth']) {
      next();
    } else {
      next({
        name: 'SignIn',
      });
    }
  } else {
    next();
  }
});

export default router
