<template>
  <v-dialog v-model="dialogShow" max-width="600px">
    <v-card :loading="loadingProjects">
      <v-card-title>
        <span>{{$t('component.fast_project_switching.title')}}</span>
        <v-spacer></v-spacer>
        <v-btn
          text
          @click="goToProjectCreate"
        >
          <v-icon left>mdi-plus</v-icon> {{ $t('btn.project_create') }}
        </v-btn>
        <v-menu
          bottom
          left
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item link :to="{name: 'ProjectManager'}" @click="dialogShow = false">
              <v-list-item-title>{{ $t('btn.project_manager') }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-card-title>
      <v-card-text>
        <v-simple-table
          dense
          fixed-header
          height="300px"
          >
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                  Name
                </th>
                <th class="text-left">
                  ID
                </th>
                <th class="text-left">
                  Created At
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="item in projects"
                :key="item.projectId"
                style="cursor: pointer;"
                @click.stop="clickToProject(item)"
              >
                <td>{{ item.humanName }}</td>
                <td>{{ item.projectId }}</td>
                <td>{{ item.createdAt }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="primary" text @click="onClose"> Close </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapGetters } from 'vuex'
import api from '../api'
import _ from 'lodash'
export default {
  props: ["show"],
  created() {
    this.loadMyProjects()
  },
  methods: {
    loadMyProjects() {
      if (!this.isAuth) {
        return
      }
      this.loadingProjects = true
      api.project.myProjects().then(res => {
        this.projects = _.orderBy(res.listProjectsList, [function(o) { return o.createdAt; }], ['desc']);
      }).finally(() => {
        this.loadingProjects = false
      })
    },
    onClose() {
      this.dialogShow = false;
    },
    goToProjectCreate() {
      if (this.$route.name != "ProjectCreate") {
        this.$router.push({name: "ProjectCreate"})
      }
      this.dialogShow = false
    },
    clickToProject(project) {
      this.selectedProject = {
        projectId: project.projectId,
        name: project.humanName,
      }
      this.dialogShow = false
    }
  },
  computed: {
    ...mapGetters({
      isAuth: 'session/isAuth',
    }),
  },
  data() {
    return {
      loadingProjects: false,
      selectedProject: null,
      dialogShow: false,
      projects: [
      ],
    };
  },
  watch: {
    dialogShow(newValue) {
      if (newValue == false) {
        // Закрыли диалог не кнопкой
        this.$emit("done", { show: newValue, project: this.selectedProject });
      }
      this.selectedProject = null
    },
    show(newValue) {
      this.dialogShow = newValue;
      if (newValue) {
        // По открытию модального окна загружаем проекты
        this.loadMyProjects()
      }
    },
  },
};
</script>
