import {notifInfo, notifError} from '../../plugins/notif'
import i18n from '../../i18n'
import api from '../../api'
import router from '../../router'
const state = {
  store: {},
}

const getters = {
  projectById: (state) => (projectId) => state.store[projectId],
}

const mutations = {
  addOrUpdateProject(state, project) {
    state.store[project.projectId] =  project
  }
}
const actions = {
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
