import api from "."

import {
  SignUpUserRequest,
} from "./customer-ui/user_pb"

import {
  User
} from "./customer-ui/user_pb_service"

export default {
  signup ({email, password, displayName, agreement}) {
    const request = new SignUpUserRequest()
    request.setEmail(email)
    request.setPassword(password)
    request.setAcceptedAgreement(agreement)
    request.setDisplayName(displayName)

    const method = User.SignUp
    return api.unary({method, request})
  },
}
